.footer1{
	border-top: solid 3px $colorPrimary;
	width: 100%;
	display: block;
	background-color: $colorPrimary;

	& .logo_footer{
		display: inline-block;
		vertical-align: top;
		width: 100%;

		& a{
			display: block;
			width: fit-content;
			& .img{
				display: block;
				width: 70%;
				margin: 0;
				& img{
					width: 100%;
				}
			}
		}
	}

	& .rs_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: .9rem;
			font-weight: 600;
			color: white;
			margin: 0;
		}

		& a{
			margin: .3rem;
			display: inline-block;
			vertical-align: middle;
			border-right: none;
			padding: 0;
			
			& p{
				margin: 0;
				& i{
					color: white;
					padding: .5rem;
				}
			}

			&:hover{
				border-right: none;
				padding-right: none;
			}
		}
	}

	& .contatofooter{
		display: block;
		width: 100%;
		
		& .tel_email_footer{
			display: inline-block;
			width: 100%;
			vertical-align: top;

			& h3{
				font-size: .9rem;
				font-weight: 600;
				color: white;
				margin: 0;
			}
			
			& a{
				display: block;
				width: fit-content;
				& p{
					font-size: .8rem;
					font-weight: 400;
					color: white;
				}
			}
		}
	}
	
	& .menu_footer{
		display: block;
		width: 100%;

		& a{
			display: block;
			width: fit-content;

			& p{
				font-size: .9rem;
				font-weight: 600;
				color: white;
				margin: 0 0 .5rem 0;
			}
		}
	}

	& .endereco_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: .9rem;
			font-weight: 600;
			color: white;
			margin: 0;

			& i{
				color: white;
			}
		}

		& p{
			font-size: .8rem;
			font-weight: 400;
			color: white;
		}
	}

	& .horarioatendimento_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: .9rem;
			font-weight: 600;
			color: white;
			margin: 0;
			& i{
				color: white;
			}
		}

		& p{
			font-size: .8rem;
			font-weight: 400;
			color: white;
		}
	}

	& .copyright_footer{
		display: inline-block;
		width: 100%;
		vertical-align: middle;

		& p{
			text-align: left;
			color: white;
			font-size: .75rem;
			font-weight: 400;
			display: inline-block;
			vertical-align: middle;
			& strong{
				color: white;
			}
		}
	}

	& .devby{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& .developed{
			display: inline-block;
			vertical-align: middle;
			width: 60%;

			& p{
				text-align: right;
				color: white;
				font-size: .75rem;
				font-weight: 400;
			}
		}

		& a{
			display: inline-block;
			vertical-align: middle;
			width: 15%;

			& .logo{
				width: 4rem;
				& img{
					width: 100%;
				}
			}

			& .logo2{
				width: 7rem;
				& img{
					width: 100%;
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	
	.footer1{
		& .devby{
			display: inline-block;
			width: 100%;
			vertical-align: middle;

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 20%;

				& .logo{
					width: 5rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 8rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.footer1{

		& .logo_footer{
			display: block;
			width: 100%;
	
			& a{
				display: block;
				width: fit-content;
				& .img{
					display: block;
					width: 90%;
					margin: 0;
					margin: auto;
					& img{
						width: 100%;
					}
				}
			}
		}
		
		& .rs_footer{
			display: block;
			width: 100%;
			& h3{
				font-size: 1rem;
				font-weight: 600;
				padding-left: 0;
				color: white;
			}

			& a{
				margin: 0 .3rem;
				display: inline-block;
				vertical-align: middle;
				border-right: none;
				padding: 0;
				
				& p{
					border: solid 1px transparent;
					border-radius: .1rem;
					transition: all .2s;
					& i{
						color: white;
						padding: .5rem;
						transition: all .2s;
					}
				}
			}
		}

		& .contatofooter{
			display: block;
			width: 100%;
	
			& .tel_email_footer{
				display: block;
				width: 100%;
	
				& h3{
					font-size: 1rem;
					font-weight: 600;
					color: white;
				}
				
				& a{
					display: block;
					width: fit-content;
					& p{
						font-size: .9rem;
						font-weight: 400;
						color: white;
					}
				}
			}
		}

		& .copyright_footer{
			& p{
				text-align: center;
			}
		}

		& .devby{
			display: block;
			width: 100%;
			& .developed{
				display: block;
				width: 100%;

				& p{
					text-align: center;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 50%;

				& .logo{
					width: 5rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 8rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}