/* COLORS */
$colorPrimary	: #2b2e34;
$colorSecondary : #fefe06;
$colorTerciary	: #2b2e34;
$colorQuarterly : #767171;

$colorDanger 	: #ff4b01;
$colorWhite 	: white;
$colorAccent 	: #5bbeff;
$tooltipColor	: #5bbeff;
$transparent    : rgba(0, 0, 0, 0);
$shadowBox 		: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
$shadowBoxHover	: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
$sizeNav		: 12px;

$gray1: #232323;
$gray2: #454545;
$gray3: #888888;
$gray4: #C3C3C3;
$gray5: #f1f1f1;

$claro: '#6576ecff';
$escuro: '#64cbecff';

$velocidadeAnima: 0.25s;
$maxPC: 1220px;

// ############################################ //
// ############################################ //
// ############################################ //

/* CONFIG BTN - BUTTONS */
$prefix: 'b';
$btnHeight: 50;

$btnBorder: 1;
$btnBorderRadius: 3;

$btnPdLeft: 30;
$btnPdRight: 30;

$btnFont: 16;
$btnFontWeight: 400;
$btnmenuHei: 30;

// ############################################ //
// ############################################ //
// ############################################ //

/* CONFIG INPUTS - FORM */
$inputHeight: 40;
$inputBorder: 1;
$inputBorderCheckbox: 1;
$inputBorderColor: $gray3;
$inputBackgroundColor: rgba(0,0,0,0);
$inputBorderRadius: 0;
$inputBorderRadiusCheckbox: 3;
$inputPdLeft: 10;
$inputPdRight: 10;

$inputTextColor: $gray1;
$inputPlaceHolder: $gray3;

$labelColor: $gray1;
$labelBackgroundColor: $inputBackgroundColor;
$inputTextSize: 13px;

$inputBorderColorFocus: $gray3;

/* MOBILE */
$mobileIncrease: 0;
$mobileTextSize: 13px;

// ############################################ //
// ############################################ //
// ############################################ //

/* TABS */
$lineColor: #DDD;
$normalColor: #F8F8F8;
$hoverColor: #F1F1F1;
$activeColor: #FFFFFF;
$textColor: #505050;
