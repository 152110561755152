.pd-right{
	padding-right: .4rem !important; 
}

.busca {
	display: block;
	width: 75%;
	text-align: center;
	z-index: 2;
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -55%);

	& .slogan{
		display: block;
		width: fit-content;
	
		& h1{
			font-size: 2rem;
			font-weight: 600;
			text-align: center;
			color: white;
			text-shadow: 0 0 4px black;
		}
	}
	
	& .busca-filtro{
		width: 100%;
		display: block;

		& .bloco_busca{
			padding: 0;
			margin: 0;
			text-align: center;
			display: block;
			width: 100%;

			& .filtros_busca_home{
				width: 20%;
				display: inline-block;
				padding: .1rem 0;
				& p{
					font-weight: 600;
					margin: 0;
				}
			}

			& .filtros_busca_home2{
				width: 25%;
				display: inline-block;
				padding: .1rem 0;
				& p{
					font-weight: 600;
					margin: 0;
				}
			}
			& .filtros_busca_home_input{
				width: 17.5%;
				display: inline-block;
				padding: .1rem 0;
			}
			& .btn-home{
				padding: .8rem .5rem;
				border-radius: .2rem;
				width: 100%;
				display: inline-block;
				vertical-align: middle;
				background-color: $colorPrimary;
				color: $colorSecondary;
				font-size: 1rem;
				font-weight: 600;
				border: none;
				cursor: pointer;
				transition: all .2s ease-in-out;
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
				
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .7rem 0;
	border: none;
	border-radius: .2rem;
	background-color: $colorPrimary;
	color: $colorSecondary;
	border: solid 1px $colorPrimary;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .7rem 0;
	border: solid 1px $gray4;
	border-radius: .2rem;
	text-transform: uppercase;
	background-color: white;
	color: $colorTerciary;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: lighten($gray4, 15);
	}
}

.btn_filtros_avancados{
	background-color: $colorPrimary;
	border: solid 1px $colorPrimary;
	display: inline-block;
	vertical-align: middle;
	width: fit-content;
	transition: all .2s ease-in-out;
	cursor: pointer;

	& p{
		color: $colorSecondary;
		font-size: .8rem;
		font-weight: 600;
		padding: .5rem 1rem;
		line-height: normal;
		text-transform: uppercase;
		text-align: center;
		margin: 0;
	}
	
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.btn_limpar_filtros{
	background-color: white;
	display: inline-block;
	vertical-align: middle;
	width: fit-content;
	transition: all .2s ease-in-out;
	cursor: pointer;

	& p{
		color: $gray1;
		font-size: .8rem;
		font-weight: 600;
		padding: .5rem 1rem;
		line-height: normal;
		transition: all .2s ease-in-out;
		text-transform: uppercase;
		text-align: center;
		margin: 0;

	}
	&:hover{
		background-color: lighten($gray4, 15);
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){

	.busca {
		display: block;
		width: 90%;
		top: 65%;
		left: 50%;
		transform: translate(-50%, -65%);

		& .busca-filtro{
			width: 100%;
			display: block;

			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
	
				& .filtros_busca_home{
					width: 20%;
					display: inline-block;
					padding: 0;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
	
				& .filtros_busca_home2{
					width: 25%;
					display: inline-block;
					padding: .1rem 0;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}

				& .filtros_busca_home_input{
					width: 17.5%;
					display: inline-block;
					padding: 0;
				}

				& .btn-home{
					padding: .7rem .5rem;
					border-radius: .2rem;
					width: 100%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorPrimary;
					color: $colorSecondary;
					font-size: 1rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					&:hover{
						background-color: darken($colorPrimary, 5);
					}
					
				}
			}
		}
	}
}

@media all and (max-width: 1023px){

	.pd-right{
		padding-right: 1rem !important; 
	}

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.busca {
		background-color: transparent;
		display: block;
		width: 85%;
		padding: 0;
		text-align: center;
		border: none;
		box-shadow: none;
		position: absolute;
		top: inherit;
		left: inherit;
		transform: inherit;

		& .slogan{
			display: block;
			width: fit-content;
		
			& h1{
				font-size: 1.5rem;
				font-weight: 600;
				text-align: center;
				color: white;
				text-shadow: 0 0 4px black;
			}
		}

		& .busca-filtro{
			width: 100%;
			display: block;
			border-radius: 1rem;
			padding: 1rem;

			& .bloco_busca{
				padding: 1rem;
				border-radius: .2rem;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
				background-color: white;
				box-shadow: 0 0 10px 0 rgba(black, .2);
				& .filtros_busca_home{
					width: 100%;
					display: inline-block;
					padding: 0 0 1rem 0 !important;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}

				& .filtros_busca_home2{
					width: 100%;
					display: inline-block;
					padding: 0 0 1rem 0 !important;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}

				& .filtros_busca_home_input{
					width: 50%;
					display: inline-block;
					padding: 0;
				}

				& .btn-home{
					padding: .95rem .5rem;
					border-radius: .2rem;
					width: 100%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorPrimary;
					color: $colorSecondary;
					font-size: 1rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					&:hover{
						background-color: darken($colorPrimary, 5);
					}
					
				}

				& .item_buscaMenu{
					display: block;
					width: 100%;
				
					& .buscaMenu{
						display: block;
						width: 100%;
						& .elementos_buscaMenu_class{
							transition: all .2s ease-in-out;
							padding-left: 1.5rem;

							& p{
								text-align: left;
								font-size: .9rem;
								color: $gray2;
								font-weight: 500;
							}
						}
					}
				
					& .title_is{
						& p{
							text-align: left;
							font-size: 1.1rem;
							color: $colorTerciary;
							font-weight: 500;
							padding: 0.1rem 0;
							border-bottom: solid 1px $gray4;
							margin: 0;
							line-height: 2rem;

							& i{
								display: inline-block;
								vertical-align: middle;
								font-size: .7rem;
								color: $colorPrimary;
								font-weight: 300;
							}
						}
					}
				}
			}
		}
	}
}

