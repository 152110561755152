.section_depoimentos{
    background-color: white;
    border-top: solid 3px $colorPrimary;

    & h2{
        text-align: center;
        color: $colorPrimary !important;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;

        & i{
            color: $colorPrimary;
            font-size: 2rem;
        }
    }

    & .miniatura_depoimento{
        display: block;
        width: 100%;
        border: solid 1px darken($gray5, 5);
        border-radius: .2rem;
        padding: 1rem;
		box-sizing: border-box;
        margin: 1rem 0;
    
        & .imagem_nome{
            display: flex;
            justify-content: flex-start;
            align-items: center;
    
            & img{
                width: 3.5rem;
                height: 3.5rem;
                margin-right: 1rem;
                border-radius: 50%;
            }
    
            & .nome{
                font-size: 1.2rem;
                font-weight: 600;
                color: $colorTerciary;
                line-height: 1.2rem;
                margin: 0;
                
                & .cargo{
                    font-size: .85rem;
                    font-weight: 400;
                    color: $gray2;
                    margin: 0;
                }
            }
        }
    
        & .depoimento{
            font-size: .9rem;
            font-weight: 400;
            color: $gray2;
            min-height: 3.7rem;
            max-height: 3.7rem;
            overflow: hidden;
        }
    
        & .vermais_btn{
            display: block;
            width: fit-content;
            margin-right: 0;
            
            & .vermais{
                font-size: .85rem;
                font-weight: 600;
                color: $colorPrimary;
                user-select: none;
                cursor: pointer;
                text-align: right;
                transition: all .2s ease-in-out;
				padding: .2rem 1rem;
				border-radius: .1rem;
                margin: 0;
                
                &:hover{
					color: white;
					background-color: $colorPrimary;
                }
            }
        }
    
        & .data{
            font-size: .8rem;
            font-weight: 400;
            color: $gray2;
            margin: 0;
        }
    }

	& .nada_encontrado{
		display: block;
		width: 100%;

		& h2{
			text-align: center;
			color: $gray1;
			font-size: 1.5rem;
			font-weight: 600;
		}

		& a{
			display: block;
			width: fit-content;
			margin: auto;
			& p{
				background-color: $colorPrimary;
				font-size: 1rem;
				font-weight: 600;
				color: white;
				padding: .2rem 1rem;
				border-radius: .1rem;
				transition: all .2s ease-in-out;
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}

    & button{
        border-radius: .2rem;
    }
}

.section_depoimentos_interna{
    width: 100%;
    background-color: white;

    .splide__arrow{
        display: none !important;
    }

    .splide__pagination{
        bottom: 2rem;
    }

    & .miniatura_depoimento{
        display: block;
        width: 100%;
        border: solid 1px darken($gray5, 5);
        border-radius: .2rem;
        padding: 1rem;
		box-sizing: border-box;
    
        & .imagem_nome{
            display: flex;
            justify-content: flex-start;
            align-items: center;
    
            & img{
                width: 3.5rem;
                height: 3.5rem;
                margin-right: 1rem;
                border-radius: 50%;
            }
    
            & .nome{
                font-size: 1.2rem;
                font-weight: 600;
                color: $colorTerciary;
                line-height: 1.2rem;
                margin: 0;
                
                & .cargo{
                    font-size: .85rem;
                    font-weight: 400;
                    color: $gray2;
                    margin: 0;
                }
            }
        }
    
        & .depoimento{
            font-size: .9rem;
            font-weight: 400;
            color: $gray2;
            min-height: 3.7rem;
            max-height: 3.7rem;
            overflow: hidden;
        }
    
        & .vermais_btn{
            display: block;
            width: fit-content;
            margin-right: 0;
            background: $colorPrimary;
            border-radius: .25rem;

            & .vermais{
                font-size: .85rem;
                font-weight: 600;
                color: #FFF;
                user-select: none;
                cursor: pointer;
                text-align: right;
                transition: all .2s ease-in-out;
				padding: .2rem 1rem;
				border-radius: .1rem;
                margin: 0;
                
                &:hover{
					color: white;
					background-color: $colorTerciary;
                }
            }
        }
    
        & .data{
            font-size: .8rem;
            font-weight: 400;
            color: $gray2;
            margin: 0;
        }
    }

	& .nada_encontrado{
		display: block;
		width: 100%;

		& h2{
			text-align: center;
			color: $gray1;
			font-size: 1.5rem;
			font-weight: 600;
		}

		& a{
			display: block;
			width: fit-content;
			margin: auto;
			& p{
				background-color: $colorPrimary;
				font-size: 1rem;
				font-weight: 600;
				color: white;
				padding: .2rem 1rem;
				border-radius: .1rem;
				transition: all .2s ease-in-out;
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}

    & button{
        border-radius: .2rem;
    }
}

.nenhum_depoimento{
    display: block;
    width: 100%;

    & h1{
        text-align: center;
        font-size: 1.3rem;
        font-weight: 500;
        color: $gray1;
    }

    & a{
        margin: auto;
        width: fit-content;
        display: block;
        & p{
            background-color: $colorTerciary;
            color: white;
            font-size: 1rem;
            font-weight: 400;
            padding: .5rem 1rem;
            border-radius: 2rem;
            transition: all .2s ease-in-out;
            &:hover{
                background-color: $colorPrimary;
            }
        }
    }
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.section_depoimentos{
        .miniatura_depoimento{
            & .imagem_nome{
                & img{
                    width: 3rem;
                    height: 3rem;
                }
            }
        }
    }
}

@media all and (max-width: 1023px){
	.section_depoimentos{
        .miniatura_depoimento{
            & .imagem_nome{
                & img{
                    width: 3.5rem;
                    height: 3.5rem;
                }
            }
        }
    }

    .section_depoimentos_interna{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2rem;
    }
}