.blogNadaEncontrado {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	& .conteudoNadaEncontrado {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;

		& h1 {
			color: $colorSecondary;
			font-size: 1.5rem;
			font-weight: 600;
		}

		& h2 {
			color: $gray2;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5rem;
			text-align: center;
			max-width: 40%;
		}

		& a {
			width: fit-content;
			display: block;
			margin: auto;

			& p {
				background-color: $colorPrimary;
				color: white;
				padding: .3rem 1rem;
				border-radius: 2rem;
				font-size: 1rem;
				font-weight: 600;
				transition: background-color .2s ease-in-out;

				&:hover {
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}
}

.conteudoBlogInterna {
	width: 100%;
	display: block;
	box-sizing: border-box;
	overflow: hidden;
	
	& iframe {
		width: 50% !important;
		height: 360px !important;
		margin: auto !important;
		text-align: center;
	}

}

.autorBlog {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: fit-content;
	margin: 0;
	gap: 1rem;

	& .img {
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(200px / 3);
		height: calc(200px / 3);
		border-radius: 50%;
		overflow: hidden;
		position: relative;

		& img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}
	}

	& .descricao_autor {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: .5rem;

		& p {
			margin: 0;
			font-size: 1rem;
			font-weight: 600;
			color: $colorPrimary;
		}

		& span {
			margin: 0;
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
		}
	}
}

.container_postagens_blog{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1.5rem;
	align-items: flex-start;
}

.miniatura-blog {
	display: block;
	width: 100%;
	margin: 0;

	& .miniatura-blog-img{
		border-radius: .5rem .5rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 60%;
		background-color: $gray1;
		position: relative;
		user-select: none;

		& img{
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}
	}

	& .miniatura-blog-detalhe{
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		position: relative;
		border-left: solid 1px lighten($gray4, 10);
		border-bottom: solid 1px lighten($gray4, 10);
		border-right: solid 1px lighten($gray4, 10);
		border-radius: 0 0 .5rem .5rem;

		& .miniatura-titulo{
			display: block;
			width: 100%;
			
			& p{
				font-size: 1.1rem;
				font-weight: 600;
				color: $gray1;
			}
		}

		& .miniatura-blog-descricao{
			display: block;
			width: 100%;
			overflow: hidden;
			max-height: 4.5rem;
			height: 4.5rem;

			& p{
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
			}

		}

		& .miniatura-btn-vermais{
			position: absolute;
			right: 5%;
			top: -12%;
			transform: translate(-5%, -12%);

			& p{
				background-color: $colorPrimary;
				border-radius: 2rem;
				border: solid 1px white;
				font-size: .9rem;
				font-weight: 500;
				color: white;
				padding: .3rem 1rem;

				&:hover{
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}

}

.blog_main_image{
	text-align: center;
	
	img{
		max-width: 750px;
	}
}

/* SÓ SE APLICA NO PC */
@media (max-width: 1023px) {
	.container_postagens_blog{
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 1.5rem;
		align-items: flex-start;
	}

	.blogNadaEncontrado {
		& .conteudoNadaEncontrado {
			& h2 {
				max-width: 100%;
			}
		}
	}

	.conteudoBlogInterna {
		& iframe {
			width: 100% !important;
			height: 200px !important;
		}
	}

	.autorBlog {
		width: 100%;
	
		& .img {
			width: calc(200px / 3.25);
			height: calc(200px / 3.25);
		}
	
		& .descricao_autor {
			& p {
				font-size: .95rem;
			}
	
			& span {
				font-size: .85rem;
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.container_postagens_blog{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1rem;
	}

	.blogNadaEncontrado {
		& .conteudoNadaEncontrado {
			& h2 {
				max-width: 60%;
			}
		}
	}
}

