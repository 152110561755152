.header1{
	background-color: transparent;
	width: 100%;
	z-index: 99999999;
}

.box_header{
	box-shadow: 0 0 20px 1px rgba(black, .15);
	z-index: 999;
	position: relative;
}

.header2{
	background-color: white;
	width: 100%;
	z-index: 9999;
	& p{
		color: $gray1 !important;
	}

	& a{
		color: $gray1 !important;
		&:hover{
			color: $colorPrimary !important;
		}
	}
}
	
.menuacao_btn{
	background-color: $colorPrimary !important;
	color: white !important;
	margin: 0%;
}

.header3{
	width: 100%;
	animation: header3pc .45s ease-in-out;
	animation-fill-mode: forwards;
	transform-origin: center center;
	z-index: 99999999;
}

@keyframes header3pc{
	0% {
		//background-image: linear-gradient(to bottom right, #00174900, #03428200);
		background-color: transparent;
	}
	100% {
		//background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
		background-color: $colorPrimary;
	}
}

.headerinterno {
	height: 5rem;
	z-index: 1;
}
.busca-top{
	width: 100%;
	background-color: $colorPrimary;
	height: 2rem;
}


#sub_menu_vendas{
	display: none;
	width: 400px;
	min-width: 230px;
	max-width: 400px;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	left: 50%;
	top: 2.4rem;
	transform: translate(-50%);
	background-color: white;
	border: solid 1px $gray4;
	box-shadow: 0px 0px 20px 1px rgba(black, .1);
	border-radius: .2rem;
	z-index: 3;

	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 30rem;
		padding: .5rem 0;

		& .subitens_header{
			display: inline-block;
			width: 50%;
			vertical-align: top;

			& .subitem_title{
				display: block;
				width: 100%;
	
				& a{
					width: fit-content;
					display: block;
					& p{
						font-weight: 500;
						font-size: .9rem;
						line-height: .9rem;
						margin: 0;
						color: $colorTerciary;
						padding: 0.5rem 1.5rem;
						text-align: left;
						transition: all .2s ease-in-out;
						border-left: solid 2px transparent;

						&:hover{
							border-left: solid 2px $colorPrimary;
							padding-left: 1.8rem;
							font-weight: 600;
						}
					}
				}
			}

			& .subitem_dorm{
				display: block;
				width: 100%;

				& a{
					width: fit-content;
					display: block;
					& p{
						font-size: .8rem;
						font-weight: 400;
						background-color: none;
						padding: 0.3rem 1.5rem;
						margin: 0;
						text-align: left;
						transition: all .2s ease-in-out;
						color: $colorTerciary;
						border-left: solid 2px transparent;
						&:hover{
							border-left: solid 2px $colorPrimary;
							padding-left: 1.8rem;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	background-color: white;

	& .superior {
		display: block;
		width: 100%;

		/* GRUPO HEADER */
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: 13%;
			padding: .5rem 0;
			& .img{
				width: 100%;
				display: block;
				
				& img {
					display: block;
					width: 100%;
				}
			}
		}

		& .sessions {
			display: inline-block;
			width: 82%;
			vertical-align: middle;

			& ul {
				display: flex;
				align-items: center;
				margin: 0%;
				padding: 0 2rem;
				list-style: none;
				justify-content: space-around;
				transition: all .25s ease;

				& .itens_header{
					display: inline-block;
					padding: 0 15px;

					& p{
						font-size: .9rem;
						color:  $colorTerciary;
						text-align: center;
						font-weight: 400;
						user-select: none;
					}


					& .atend{
						font-size: .8rem !important;
						margin: 0;
					}

					& a{
						color: $colorTerciary;
						font-weight: 400;
						font-size: .9rem;
						transition: all .2s ease-in-out;
						text-transform: initial;
						user-select: none;

						& i{
							transition: all .1s ease-in-out;
							font-size: 1.3rem;
						}

						& .hover_scale{
							&:hover{
								transform: scale(1.1);
							}
						}

						& .wpp_headerr{
							font-weight: 600;
							font-size: .9rem;
							margin: 0;
							transition: all .2s ease-in-out;
							&:hover{
								color: $colorPrimary;
							}
						}

						&:hover{
							color: $colorPrimary;
							
						}
					}

					& .itemsession{
						position: relative;
						display: inline-block;
						vertical-align: middle;
						width: 100%;
	
						& .title_is{
							width: 100%;
							display: block;
	
							& .itemsession_title{
								vertical-align: middle;
								display: inline-block;
								width: 100%;
								font-size: .9rem;
								color:  $colorTerciary;
								text-align: center;
								font-weight: 400;
								padding: 0 1rem;
								@include border-box;
								transition: all .2s;
								& i{
									vertical-align: middle;
									display: inline-block;
									font-size: .65rem;
									color:  $colorPrimary;
									font-weight: 300;
								}
							}
						}
	
						&:hover{
							#sub_menu_vendas {
								display: initial;
							}
						}
						
					}
				}
				
				& .itens_header2{
					display: inline-block;
					padding: 0 20px;
					& a{
						justify-content: space-between;
						color: white;
						font-weight: 600;
						font-size: 1.1rem;
						padding: .5rem 1rem;
						background-color: $colorPrimary;
						border-radius: .1rem;
						&:hover{
							background-color: darken($colorPrimary, 5);
						}
					}
				}
			}
		}

		& .menu {
			text-align: right;
			display: inline-block;
			width: 5%;
			vertical-align: middle;
		}

	}
}

.headerindex {
	height: calc(100vh - 8.3rem);
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(black, .2);
			//background: linear-gradient(to bottom, rgba(black, .5) 0%, transparent 35%);
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;//era block
			height: calc(100vh + 1px);
			min-height: 40rem;
			width: 100%;
			z-index: 2;
			background-image: url('/jnh/header/bg_header1.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center 0%;
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (min-width: 1024px) and (max-width: 1366px){

	.headerindex {
		display: block;
		height: calc(100vh - 6.6rem);
		padding: 0;
		@include border-box;
		z-index: 2;
		
		& .container-bg-headerpc{
			display: block;
			position: absolute;
			top:0;
			overflow: hidden;
			z-index: -1;
			height: 100%;
			width: 100%;
	
			& .overlay_bg_home{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				//background-color: rgba(black, .3);
				background: linear-gradient(to bottom, rgba(black, .5) 0%, transparent 40%);
				z-index: 2;
			}
		}
	}

	.headerpc {
		display: block;
		width: 100%;
		position: relative;

		& .superior {
			display: block;
			width: 100%;
			color: #ffffff;
			z-index: 3;

			/* GRUPO HEADER */
			& .logo {
				display: inline-block;
				margin-left: 0;
				@include border-box;
				vertical-align: middle;
				width: 13%;
				padding: .5rem 0;

				& .img{
					width: 100%;
					display: block;
					
					& img {
						display: block;
						width: 100%;
					}
				}
			}

			& .sessions {
				display: inline-block;
				width: 82%;
				vertical-align: middle;
				& ul {
					& .itens_header{
						display: inline-block;
						padding: 0 15px;
						& a{
							font-size: .9rem;

							& i{
								font-size: 1.1rem;
							}
						}
	
						& .itemsession{
							& .title_is{
								& .itemsession_title{
									font-size: .9rem;
								}
							}
						}
					}
				}
			}

			& .menu {
				text-align: right;
				display: inline-block;
				width: 5%;
				vertical-align: middle;
			}
		}
	}
}

@media all and (max-width: 1023px){
	
	.headerindex {
		display: block;
		height: calc(100vh - 6.3rem);
		padding: 0;
		@include border-box;
		z-index: 999;
		background-color: $gray4;
		
		& .container-bg-headerpc{
			display: block;
			position: absolute;
			top:0;
			overflow: hidden;
			z-index: 0;
			height: 100%;
			width: 100%;
	
			& .overlay_bg_home{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				//background-color: rgba(black, .3);
				background: linear-gradient(to bottom, rgba(black, .5) 0%, transparent 40%);
				z-index: 2;
			}
	
			& .bg-headerpc{
				display: block;//era block
				height: calc(100vh + 1px);
				min-height: 40rem;
				width: 100%;
				z-index: 2;
				background-image: url('/jnh/header/bg_header1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center right 42%;
			}
		}
	}
	
	.headerpc{
		
		& .logo{
			padding: 0.3rem 0 0 0 !important;
		}
	}
	
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}
	
}
