.pag_cond {
    display: block;
    width: 100%;

    & h1{
        color: $colorPrimary;
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
    }

    & h2{
        color: $colorTerciary;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
    }

    & .texto1{
        display: block;
        width: 100%;
        padding: 0 5rem 0 0;

        & .p1{
            color: $gray2;
            font-size: 1.5rem;
            font-weight: 500;
        }

        & .p2{
            color: $gray2;
            font-size: 1.1rem;
            font-weight: 400;
        }
    }

    & .imgcond{
        display: block;
        width: 100%;

        & img{
            width: 100%;
            border-radius: .1rem;
        }
    }

    & h3{
        text-align: center;
        color: $colorTerciary;
        font-size: 1.5rem;
        font-weight: 600;
    }
}

@media all and (max-width: 1023px){
    .pag_cond {
        display: block;
        width: 100%;
    
        & h1{
            color: $colorPrimary;
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
        }
    
        & h2{
            color: $colorTerciary;
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
        }
    
        & .texto1{
            display: block;
            width: 100%;
            padding: 0;
    
            & .p1{
                color: $gray2;
                font-size: 1.2rem;
                font-weight: 500;
            }
    
            & .p2{
                color: $gray2;
                font-size: 1rem;
                font-weight: 400;
            }
        }
    
        & .imgcond{
            display: block;
            width: 100%;
    
            & img{
                width: 100%;
                border-radius: .1rem;
            }
        }
    
        & h3{
            text-align: center;
            color: $colorTerciary;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }
}