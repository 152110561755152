.imoveis_semelhantes{
	display: block;
	width: 100%;
	background-color: white;
	border: solid 1px $gray5;
	box-shadow: 0 0 10px 0 rgba($gray4, .5);
	padding: 1rem;
	border-radius: .2rem;

	& h2{
		font-size: 1.1rem;
		text-align: left;
		color: $gray1;
		font-weight: 600;
		margin: 0;
	}

	& .miniatura_interna{
		background-color: white;
		width: 100%;
		display: block;
		padding: .7rem;
		border: solid 1px lighten($gray4, 10);
		border-bottom: none;
		position: relative;
		border-radius: .2rem;

		& a{
			display: block;
			width: 100%;
	
			& .bloco_img{
				width: 40%;
				display: inline-block;
				vertical-align: top;
				margin: 0;
	
				& .miniatura_imovel_img{
					display: block;
					width: 100%;
					overflow: hidden;
					padding-top: 60%;
					background-color: $gray5;
					position: relative;
					user-select: none;
					border-radius: .2rem;
			
					& img{
						display: block;
						width: 100%;
						position: absolute;
						left: 0;
						top: -30%;
						border-radius: .2rem;
					}
				}
			}
		   
			& .bloco_informacoes{
				width: 60%;
				display: inline-block;
				vertical-align: top;
				margin: 0;
				padding-left: 1rem;

				& .miniatura_informacoes{
					display: block;
					width: 100%;

					& .miniatura_titulo{
						display: block;
						width: 100%;
				
						& p{
							font-size: .9rem;
							font-weight: 500;
							color: $gray1;
							margin: 0;
						}
					}
						
					& .codigo_imovel{
						width: 100%;
						display: block;

						& p{
							font-size: .8rem;
							font-weight: 400;
							color: $gray1;
						}
															
						& .miniatura_acao{
							font-size: .8rem;
							font-weight: 400;
							margin: 0;
							color: $gray2;
							background-color: transparent;
						}
					}
	
					& .miniatura_endereco{
						display: block;
						width: 100%;
						& p{
							font-size: .8rem;
							font-weight: 400;
							color: $gray2;
							margin: 0;

							& i{
								font-size: .7rem;
								font-weight: 600;
								color: $colorPrimary;
							}
						}
					}

					& .miniatura_preco{
						display: block;
						width: 100%;

						& p{
							width: 100%;
							text-align: left;
							font-size: 1rem;
							font-weight: 500;
							color: $colorTerciary;
							line-height: none;
							margin: 0;
						}

						& .tt{
							font-size: .75rem;
							color: $gray2;
							font-weight: 400;
							margin: .5rem 0 0 0;
						}
					}
				}
			}
		}
	}

	& .miniatura-imovel-btns-iconsX{
		display: block;
		width: 100%;
		background-color: lighten($gray5, 1);
		border: solid 1px lighten($gray4, 10);
		border-top: none;
		padding: .5rem;

		& ul{
			& li{
				color: $gray1;
				font-size: .7rem;
				font-weight: 500;

				& i{
					font-weight: 400;
					font-size: 1rem;
				}
			}

		}
	}

	& .btn_vermais_interna{
		display: block;
		width: fit-content;
		margin: 0;
		& a{
			& p{
				font-size: .8rem;
				font-weight: 600;
				color: $colorTerciary;
				background-color: white;
				border-radius: .2rem;
				border: solid 1px $gray4;
				padding: .3rem 1rem;
				text-align: center;
				transition: all .2s ease-in-out;
				margin: 0;
				&:hover{
					background-color: lighten($gray4, 15);
				}
			}
		}
	}
}


@media all and (min-width: 1024px) and (max-width: 1366px){
	.imoveis_semelhantes{
		& .miniatura_interna{
			& a{
				& .bloco_img{
					& .miniatura_imovel_img{
						display: block;
						width: 100%;
						overflow: hidden;
						padding-top: inherit;
						background-color: $gray5;
						position: relative;
						user-select: none;
						border-radius: .1rem;
						max-height: 8rem;
						
						& img{
							display: block;
							width: 100%;
							position: inherit;
							left: inherit;
							top: inherit;
							border-radius: .1rem;
						}
					}
				}
			   
				& .bloco_informacoes{
					width: 60%;
					display: inline-block;
					vertical-align: top;
					margin: 0;
					padding-left: 1rem;
	
					& .miniatura_informacoes{
						display: block;
						width: 100%;
	
						& .miniatura_titulo{
							display: block;
							width: 100%;
					
							& p{
								font-size: .8rem;
								font-weight: 500;
								color: $gray1;
								margin: 0;
							}
						}
								
						& .miniatura_endereco{
							display: block;
							width: 100%;
							& p{
								font-size: .75rem;
								font-weight: 400;
								color: $gray2;
								margin: .2rem 0 0 0;
	
								& i{
									font-size: .7rem;
									font-weight: 600;
									color: $colorTerciary;
								}
							}
						}
							
						& .codigo_imovel{
							width: 100%;
							display: block;
	
							& p{
								font-size: .75rem;
								font-weight: 400;
								color: $gray1;
							}
																
							& .miniatura_acao{
								font-size: .75rem;
								font-weight: 400;
								margin: .2rem 0 0 0;
								color: $gray2;
								background-color: transparent;
							}
						}

						& .miniatura_preco{
							display: block;
							width: 100%;
	
							& p{
								width: 100%;
								text-align: left;
								font-size: 1rem;
								font-weight: 500;
								color: $colorTerciary;
								line-height: none;
								margin: .2rem 0 0 0;
							}
						}
					}
				}
			}
		}
	
		& .miniatura-imovel-btns-iconsX{
			display: block;
			width: 100%;
			background-color: lighten($gray5, 1);
			border: solid 1px lighten($gray4, 10);
			border-top: none;
			padding: .5rem;
	
			& ul{
				& li{
					color: $gray1;
					font-size: .7rem;
					font-weight: 500;
	
					& i{
						font-weight: 400;
						font-size: .9rem;
					}
				}
	
			}
		}
	}
	
}

@media all and (max-width: 1023px){
	.imoveis_semelhantes{
		display: block;
		width: 100%;
		background-color: white;
		border: solid 1px $gray5;
		box-shadow: 0 0 10px 0 rgba($gray4, .5);
		padding: 1rem;
		border-radius: .2rem;
	
		& h2{
			font-size: 1.1rem;
			text-align: left;
			color: $gray1;
			font-weight: 600;
			margin: 0;
		}
	
		& .miniatura_interna{
			background-color: white;
			width: 100%;
			display: block;
			padding: .7rem;
			border: solid 1px lighten($gray4, 10);
			position: relative;
			border-radius: .2rem;
	
			& a{
				display: block;
				width: 100%;
		
				& .bloco_img{
					width: 100%;
					display: block;
					margin: 0;
		
					& .miniatura_imovel_img{
						display: block;
						width: 100%;
						overflow: hidden;
						padding-top: 60%;
						background-color: $gray5;
						position: relative;
						user-select: none;
						border-radius: .2rem;
				
						& img{
							display: block;
							width: 100%;
							position: absolute;
							left: 0;
							top: -30%;
							border-radius: .2rem;
						}
					}

				}
			   
				& .bloco_informacoes{
					width: 100%;
					display: block;
					margin: 1rem 0 0 0;
					padding-left: 0;
					
					& .miniatura_informacoes{
						display: block;
						width: 100%;
	
						& .miniatura_titulo{
							display: block;
							width: 100%;
					
							& p{
								font-size: .9rem;
								font-weight: 500;
								color: $gray1;
								margin: 0;
							}
						}
								
						& .miniatura_endereco{
							display: block;
							width: 100%;

							& p{
								font-size: .8rem;
								font-weight: 400;
								color: $gray2;
								margin: 0;
	
								& i{
									font-size: .7rem;
									font-weight: 600;
									color: $colorTerciary;
								}
							}
						}
							
						& .codigo_imovel{
							width: 100%;
							display: block;
	
							& p{
								width: fit-content;
								display: inline-block;
								vertical-align: middle;
								font-size: .8rem;
								font-weight: 400;
								color: $gray1;
								padding-right: 1rem;
							}
																
							& .miniatura_acao{
								width: fit-content;
								display: inline-block;
								vertical-align: middle;
								font-size: .8rem;
								font-weight: 400;
								color: $gray2;
								background-color: transparent;
							}
						}
		
						& .miniatura-imovel-btns-icons{
							display: block;
							width: 100%;
				
							& ul{
								& li{
									color: $gray2;
									font-size: .7rem;
									font-weight: 500;
	
									& span{
										font-size: 1rem;
										color: $colorSecondary;
									}
								}
							}
						}

						& .miniatura_preco{
							display: block;
							width: 100%;
															
							& .tt{
								font-size: .9rem;
								color: $gray2;
								font-weight: 400;
								margin: 0;
							}

							& p{
								width: 100%;
								text-align: left;
								font-size: 1.1rem;
								font-weight: 500;
								color: $colorTerciary;
								line-height: none;
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
	
	.btn_vermais_interna{
		display: block;
		width: fit-content;
		margin: 0;
		& a{
			& p{
				font-size: .9rem;
				font-weight: 600;
				color: white;
				background-color: $colorPrimary;
				border-radius: .2rem;
				padding: .5rem 1rem;
				text-align: center;
				transition: all .2s;
				&:hover{
					background-color: lighten($gray4, 15);
				}
			}
		}
	}
}