.section_acoes{
	background-color: white;
	display: block;
	width: 100%;

	& .min_apart{
		width: 100%;
		display: block;
		@include border-box;
		padding: 1rem;

		& a{
			width: 100%;
			display: block;
			position: relative;

			& img{
				transition: all .25s;
				width: 100%;
				border-radius: 1rem;
				box-shadow: 0 0 20px 5px rgba(black, .2);
			}

			& .txt{
				position: absolute;
				top: 42%;
				right: 50%;
				transform: translate(50%, 42%);
				display: block;
				width: 100%;

				& .title{
					text-align: center;
					width: 100%;
					background-color: rgba($colorTerciary, .9);
					padding: .5rem 0;
					font-weight: 600;
					color: white;
					font-size: 1.1rem;
				}
			}

			&:hover{
				& img{
					filter: brightness(1.5); 
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.section_acoes{
		& .min_apart{
			& a{
				& .txt{
					position: absolute;
					top: 35%;
					right: 50%;
					transform: translate(50%, 35%);
					display: block;
					width: 100%;

				}
			}
		}
	}
}