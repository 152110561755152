.header-mob{
	background-color: transparent;
	& .box_logo_mobile{
		width: 40%;
		display: inline-block;
		vertical-align: middle;
		& .log{
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			
			& img{
				width: 100%;
			}
		}
	}

	& .box_btn_menu_mobile{
		width: 60%;
		display: inline-block;
		vertical-align: middle;
		text-align: right;

		& .btn_busca_home{
			display: inline-block;
			vertical-align: middle;
			width: 70%;
			& p{
				user-select: none;
				width: 100%;
				text-align: center;
				color: white;
				font-weight: 600;
				font-size: 1rem;
				background-color: $colorPrimary;
				border-radius: .1rem;
				padding: .3rem .5rem;
				margin-right: 0 !important;
			}
		}
		& .btn-men{
			display: inline-block;
			vertical-align: middle;
			width: 30%;
		}
	}
}