.title_condomi{
	text-align: center;
	& h1{
		font-size: 2.3rem;
		font-weight: 600;
		color: $colorTerciary;
	}
	
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: $colorTerciary;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorSecondary;
	}
}

.title_condomi2{
	text-align: center;
	
	& h2{
		font-size: 1.75rem;
		font-weight: 600;
		color: white;
		margin-top: 0;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 3px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.blog_section{
	width: 100%;
	display: block;
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: $colorTerciary;
		text-align: center;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.section_servicos{
	width: 100%;
	display: block;
	background-color: lighten($gray5, 1);

	& a{
		& .bloco_servicos{
			width: 100%;
			background-color: white;
			border: solid 1px transparent;
			border-radius: .2rem;
			padding: 2rem;
			height: 20rem;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all .2s ease-in-out;
			box-shadow: 0 0 20px 0 rgba(black, .1);

			& .content_serv{
				display: block;
				width: fit-content;
				max-width: 30rem;
				margin: auto;
				z-index: 2;
	
				& img{
					width: 23%;
					display: block;
					margin: auto;
				}
	
				& h2{
					color: $colorPrimary;
					font-size: 1.1rem;
					font-weight: 600;
					text-align: center;
					border-bottom: solid 1px $gray4;
					padding-bottom: 1rem;
				}
		
				& p{
					color: $colorPrimary;
					font-size: .9rem;
					font-weight: 400;
					text-align: center;	
					padding: 0;
					margin: 0;
				}
			}
		}

		&:hover{
			& .bloco_servicos{
				border: solid 1px $colorSecondary;
			}
		}
	}

	& .bloco_qrcode{
		width: 100%;
		background-color: white;
		border: solid 1px transparent;
		border-radius: .2rem;
		height: 20rem;
		padding: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 0 20px 0 rgba(black, .1);
		
		& .content_serv{
			display: block;
			width: fit-content;
			max-width: 30rem;
			margin: auto;
			z-index: 2;
			text-align: center;

			& img{
				width: 65%;
				display: inline-block;
				vertical-align: middle;
				margin: auto;
			}

			& .wpp_ven_loc{
				margin: 0;
				font-size: 1.1rem;
				font-weight: 600;
			}

			& hr{
				margin-top: 1rem;
				margin-bottom: 1rem;
				height: .06rem;
				background-color: $gray4;
			}

			& p{
				color: $colorPrimary;
				font-size: .9rem;
				font-weight: 400;
				text-align: center;	
				padding: 0;
				margin: 0;
			}
		}
	}
}

.section_tipos{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;

	& .miniatura_tipo{
		display: block;
		width: 100%;
		border: solid 1px lighten($gray4, 10);
		box-shadow: 0 0 10px 0 rgba(black, .1);
		box-sizing: border-box;
		padding: 1rem;
		border-radius: .2rem;
		transition: all .2s ease-in-out;
		margin-bottom: 2rem;

		& img{
			width: 100%;
			display: block;
		}

		& p{
			font-size: .9rem;
			font-weight: 600;
			color: $colorSecondary;
			text-align: center;
			background-color: $colorPrimary;
			border-radius: .2rem;
			padding: .4rem 0;
			line-height: inherit;
			margin: 0;
		}

		&:hover{
			transform: scale(1.1);
			box-shadow: 0 0 15px 0 rgba(black, .1);
		}
	}
}

.bg_busca_interna{
	background-color: white;
	display: block;
	width: 100%;

	& h2{
		text-align: center;
		font-size: 1.8rem;
		font-weight: 600;
	}

	& .titulo-formulario{
		font-size: .85rem;
		font-weight: 400;
		color: $gray1;
		line-height: normal;
	}
}

.b_submit_email{
	width: 100%;
	border: none;
	padding: .5rem 0;
	background-color: $colorTerciary;
	color: white;
	text-transform: uppercase;
	border-radius: 2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease;
	&:hover{
		background-color: darken($colorTerciary, 2);
	}
}

.botao_voltar{
	background-color: $colorPrimary;
	border: none;
	border-radius: .2rem;
	padding: .2rem 1rem;
	color: white !important;
	font-size: .8rem;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
}

.botao_voltar_interna{
	background-color: $colorPrimary;
	border: none;
	border-radius: .2rem;
	padding: .3rem 1rem;
	color: $colorSecondary !important;
	font-size: .8rem;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.title_contato{
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorTerciary;
	vertical-align: middle;
	& img{
		vertical-align: middle;
		display: inline-block;
		width: 2rem;
	}
}

@media all and (max-width: 1023px){

	.title_condomi{
		text-align: center;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
		}
		& p{
			font-size: 1rem;
			font-weight: 400;
			color: $gray3;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}
	.blog_section{
		width: 100%;
		display: block;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	.section_servicos{
		& .bloco_qrcode{
			width: 100%;
			background-color: white;
			border: solid 1px transparent;
			border-radius: .2rem;
			height: 20rem;
			padding: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0 0 20px 0 rgba(black, .1);
			
			& .content_serv{
				display: block;
				width: fit-content;
				max-width: 30rem;
				margin: auto;
				z-index: 2;
				text-align: center;
	
				& img{
					width: 65%;
					display: inline-block;
					vertical-align: middle;
					margin: auto;
				}
	
				& .wpp_ven_loc{
					margin: 0;
					font-size: 1.1rem;
					font-weight: 600;
				}
	
				& hr{
					margin-top: 1rem;
					margin-bottom: 1rem;
					height: .06rem;
					background-color: $gray4;
				}
	
				& p{
					color: $colorPrimary;
					font-size: .9rem;
					font-weight: 400;
					text-align: center;	
					padding: 0;
					margin: 0;
				}
			}
		}
	}

	.section_tipos{
		& .miniatura_tipo{
			width: 70%;
		}
	}
}

@media all and (min-width: 320px) and (max-width: 1023px){
	.max-sz-con{
		max-width: 40rem !important;
		padding-left: 2rem !important;
		padding-right: 1rem !important;
	}
}

@media all and (max-width: 1023px){
	.page-wrap{
		display: block;
	}
	.page-wrap .width-50, .main-content, .main-content2{
		width: 100%;
	}
}