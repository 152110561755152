/* MINIATURA 1 */
.miniatura-imv-status{
	top: 20px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorPrimary;
	padding: 5px 10px;
	color: #ffffff;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.miniatura-imv-mobiliado{
	top: 72px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorTerciary;
	padding: 5px 10px;
	color: #ffffff;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.imoveldestaque{
	top: 46px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorQuarterly;
	padding: 5px 10px;
	color: $gray1;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.cr {
	width: 100%;
	padding: .3rem;
	position: absolute;
	text-align: center;
	color: #f0f0f0;
}
  
.cr-sticky {
position: absolute;
}
  
/* Positions */

.cr-top    { top: 30px; }
.cr-bottom { bottom: 25px; }
.cr-left   { left: -120px; }
.cr-right  { right: -35px; }

/* Rotations */

.cr-top.cr-left,
.cr-bottom.cr-right {
	transform: rotate(-45deg);
}

.cr-top.cr-right,
.cr-bottom.cr-left {
	transform: rotate(45deg);
}
  
/* Colors */
.cr-primary{
	background-color: rgba($colorTerciary, .5);
	font-weight: 500;
	color: $colorPrimary;
	font-size: .75rem;
}

.linkg{
	&:hover{
		color: $colorPrimary !important;
	}
}

.pd-05{
	padding-top: 0.3rem;
}

.miniatura-imovel {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	position: relative;
	& .miniatura-imovel-img{
		display: block;
		border-radius: .2rem .2rem 0 0;
		width: 100%;
		background-color: $gray4;
		user-select: none;
		border: solid 1px $gray4;
		border-bottom: none;
		position: relative;
		overflow: hidden;

		& img{
			display: block;
			width: 100%;
			border-radius: .2rem .2rem 0 0;
		}

		& .miniatura_status{
			position: absolute;
			top: .5rem;
			left: 0rem;
			background-color: rgba($colorPrimary, .9);
			font-weight: 600;
			color: white;
			font-size: .75rem;
			padding: .2rem 1rem;
			border-radius: 0 .2rem .2rem 0;
			margin: 0;
		}

		& .tarja_vendido_miniatura{
			position: absolute;
			top: 45%;
			left: -25%;
			transform: translate(25%, -45%);
			width: 150%;
			text-align: center;
			background-color: rgba($colorTerciary, .9);
			color: white;
			font-size: 1.5rem;
			padding: .5rem 0;
			text-transform: uppercase;
			transform: rotate(335deg);
		}
	}

	& .miniatura-imovel-detalhe{
		display: block;
		border-left: solid 1px $gray4;
		border-right: solid 1px $gray4;
		border-radius: 0 0 .1rem .1rem;
		width: 100%;
		padding: 1rem 1rem 0 1rem;
		background-color: white;
		@include border-box;
		position: relative;

		& .miniatura-acao{
			position: absolute;
			right: 1rem;
			top: -1.4rem;

			& p{
				background-color: white;
				border: solid 1px $colorPrimary;
				border-radius: .3rem;
				font-size: .9rem;
				font-weight: 500;
				color: $colorPrimary;
				padding: .2rem .5rem;
			}
		}

		& .miniatura-titulo{
			display: block;
			width: 100%;

			& p{
				text-transform: uppercase;
				font-size: .85rem;
				font-weight: 600;
				color: $colorTerciary;
				margin: 0;

				& i{
					color: $colorPrimary;
				}
			}

			& .miniatura_endereco{
				display: block;
				width: 100%;

				& .aca_tip{
					font-size: .8rem;
					font-weight: 500;
					color: $gray2;
					padding: .5rem 0;
					width: 60%;
					display: inline-block;
					vertical-align: middle;
					text-transform: initial;
				}

				& .miniatura-cod{
					display: inline-block;
					vertical-align: middle;
					width: 40%;

					& p{
						font-size: .8rem;
						font-weight: 500;
						text-align: right;
						color: $gray2;
						margin: 0;
						line-height: normal;
					}
				}
			}
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			height: 3rem;

			& ul{
				& li{
					color: $gray2;
					font-weight: 400;
					font-size: .8rem;
				}
			}
		}
	}

	& .miniatura-preco{
		display: block;
		padding: 1rem;
		width: 100% !important;
		background-color: white;
		border-left: solid 1px $gray4;
		border-right: solid 1px $gray4;
		border-bottom: solid 1px $gray4;
		border-radius: 0 0 .2rem .2rem;
		cursor: pointer;

		& .imo_preco_miniatura{
			padding: .3rem 0;
			width: 100%;
			margin: 0;
			text-align: center;
			font-size: 1.1rem;
			font-weight: 600;
			color: $colorSecondary;
			background-color: $colorPrimary;
			border-radius: .2rem;
			transition: all .2s ease-in-out;
			&:hover{
				background-color: darken($colorPrimary, 5);
			}
		}

		& .btn_vermais_miniatura{
			padding: .45rem 0;
			width: 100%;
			margin: 0;
			text-align: center;
			font-size: .9rem;
			font-weight: 600;
			color: $colorPrimary;
			background-color: transparent;
			border-radius: .2rem;
			transition: all .2s ease-in-out;

			&:hover{
				background-color: lighten($gray4, 15);
			}
		}
	}
}

.splide__arrow--prev{
	left: -2rem !important;
}

.splide__arrow--next{
	right: -2rem !important;
}

/* SÓ SE APLICA NO PC */
@media all and (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}

}

@media all and (min-width: 1201px) and (max-width: 1366px){
	.splide__arrow--prev{
		left: 2rem !important;
	}
	
	.splide__arrow--next{
		right: 2rem !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1200px){
	.splide__arrow--prev{
		left: 0 !important;
	}
	
	.splide__arrow--next{
		right: 0 !important;
	}
}

